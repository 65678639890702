<template lang="pug">
  .gallery
    section.hero
      .hero__container
        h1.hero__title.title
          | Галерея
          |
          span VIDAR
        p.hero__desc
          | «Vidar» - це сучасний спортивний центр для дітей і молоді,
          | який сприяє здоровому розвитку організму.
        h2.hero__sub-title Галерея
    section.photo
      .photo__container
        .photo__list(v-if="images")
          img.image(
            v-for="(image, i) in images"
            :key="i"
            @click="index = i"
            :src="getImagePath(image.directus_files_id)"
            alt="img"
          )
          vue-gallery-slideshow(
            :images="imagesList"
            :index="index"
            @close="index = null"
          )
        h2(v-else) Галерея пуста
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow'

export default {
  name: 'Gallery',
  components: {
    VueGallerySlideshow
  },
  data () {
    return {
      imagesList: [],
      images: [],
      index: null
    }
  },
  mounted () {
    this.fetchImages()
  },
  methods: {
    async fetchImages () {
      try {
        const response = await this.$api.get('/items/gallery_files')
        this.images = response.data.data
        this.copyArray()
      } catch (error) {
        console.error('Error fetching news:', error)
      }
    },
    getImagePath (image) {
      return `${ process.env.VUE_APP_API }/assets/${image}`
    },
    copyArray () {
      this.imagesList = this.images.map(item => this.getImagePath(item.directus_files_id))
    }
  }
}
</script>
